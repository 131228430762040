import React, { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import './HikingMap.scss';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import MountFields from "../MountFields/MountFields";
// import Logo from "../Logo/Logo";
import FullScreenButton from "../FullScreenButton/FullScreenButton";
import DefaultScreenButton from "../DefaultScreenButton/DefaultScreenButton";
import BurgerButton from "../BurgerButton/BurgerButton";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import HikingModal from "../HikingModal/HikingModal";
import { IPath } from "../../types/path";
import { useMediaQuery } from "react-responsive";
import i18n from "../../i18n";
import MountFieldsEn from "../MountFields/MountFieldsEn";
import { isFullScreenSupported } from "../../helpers/common";
import ZoomNavigation from "../ZoomNavigation/ZoomNavigation";


const HikingMap = () => {
  const refZoom = useRef<ReactZoomPanPinchRef>(null);
  const handle = useFullScreenHandle();
  const [isFullScreen, setFullScreen] = useState(false);
  const [paths, setPaths] = useState<{
    easy: Array<IPath>
    hard: Array<IPath>
    medium: Array<IPath>
  }>({
    easy: [],
    hard: [],
    medium: [],
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const [isNavOpen, setNavOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState<IPath | null>(null);

  const params = new URLSearchParams(window.location.search);
  const lng = params.get('lng');
  const season = params.get('season');

  const fetchPaths = () => {
    fetch('https://bukovel.com/api/v2/hiking-map',
      {
        method: 'GET',
        headers: {
          'Accept-Language': lng || 'uk',
        },
      })
      .then(response => response.json())
      .then((response: any) => {
        setPaths(response.data)
      });
  }

  useEffect(() => {
    fetchPaths()

    i18n.changeLanguage(lng || 'uk')

    if (season) {
      const r = document.querySelector(':root');
      // @ts-ignore
      r.style.setProperty('--brand-color', season === 'summer' ? `rgb(62, 154, 54)` : `#00b1b2`);
    }

  }, [])

  const onPathClick = (alias: string | number, zoomToElement: any) => {
    setCurrentPath(null)
    zoomToElement(`path-hiking-${alias}`)

    setTimeout(() => {
      const easyItem = paths.easy.find((item) => item.alias === alias)
      const mediumItem = paths.medium.find((item) => item.alias === alias)
      const hardItem = paths.hard.find((item) => item.alias === alias)
      if (easyItem || mediumItem || hardItem) {
        //@ts-ignore
        setCurrentPath(easyItem || mediumItem || hardItem)
      }
    }, 300)
  }

  const onClickFullScreen = (cb: any) => {
    if (!isFullScreenSupported) {
      // @ts-ignore
      window.parent.postMessage(isFullScreen ? 'leave-fullscreen' : 'enter-fullscreen', '*');
      setFullScreen(!isFullScreen);
    } else {
      cb()
    }
  }


  return (
    <FullScreen handle={handle}>
      <div className="map">

        <div className="map__zoom">
          <ZoomNavigation
            onMinus={() => {
              if (refZoom?.current?.zoomOut()) {
                refZoom.current.zoomOut()
              }

            }} onPlus={() => {
            if (refZoom?.current?.zoomIn) {
              refZoom.current.zoomIn()

            }

          }}/>
        </div>

        <div className="map__burger-button" onClick={() => setNavOpen(true)}>
          <BurgerButton/>
        </div>


        <div className="map__button">
          {handle.active
            ? <DefaultScreenButton onClick={() => onClickFullScreen(handle.exit)}/>
            : <FullScreenButton onClick={() => onClickFullScreen(handle.enter)}/>}
        </div>


        {/* LOGO */}
        {/*<div className="map__logo"><Logo/></div>*/}

        <TransformWrapper
          ref={refZoom}
          initialScale={isTabletOrMobile ? 4 : 1.5}
          limitToBounds={true}
          centerOnInit
          minScale={isTabletOrMobile ? 4 : 0.6}
          maxScale={isTabletOrMobile ? 8 : 3}

        >
          {({ zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => {

            return (
              <>
                {isNavOpen
                  && <div className="map__nav">
                    <NavigationMenu
                      currentPath={currentPath}
                      paths={paths}
                      onMenuClick={(item) => {
                        setCurrentPath(null)
                        zoomToElement(`path-hiking-${item.alias}`)

                        setTimeout(() => {
                          setCurrentPath(item)
                        }, 300)

                        if (isTabletOrMobile) {
                          setNavOpen(false)
                        }

                      }}
                      onClose={() => setNavOpen(false)}/>
                  </div>
                }

                <TransformComponent

                  wrapperStyle={{
                    width: 'auto',
                    height: '100vh',
                    background: '#1e3c20'
                  }}
                >
                  <div className="hiking-map">

                    {/* MAIN BACKGROUND */}
                    <img src="/images/map/background.png" width={2560} height={1290} alt="map"
                         className="hiking-map__background"/>

                    {/* ON THE MAP */}
                    <div className="hiking-map__fields">

                      {lng && lng !== 'uk' ?
                        <MountFieldsEn
                          currentPath={currentPath}
                          onPathClick={(alias) => onPathClick(alias, zoomToElement)}
                        />
                        :
                        <MountFields
                          currentPath={currentPath}
                          onPathClick={(alias) => onPathClick(alias, zoomToElement)}
                        />
                      }


                    </div>


                  </div>
                </TransformComponent>

                {currentPath && <div className="hiking-map__modal">
                  <HikingModal path={currentPath} onClose={() => {
                    setCurrentPath(null)
                    // resetTransform(0)
                    zoomOut(1)
                  }}/>
                </div>}

              </>

            )
          }}
        </TransformWrapper>
      </div>
    </FullScreen>
  );
};

export default HikingMap