import React from "react";


const ZoomButtonMinus = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2047_16651)">
        <path d="M54 27C54 13.1929 42.8071 2 29 2C15.1929 2 4 13.1929 4 27C4 40.8071 15.1929 52 29 52C42.8071 52 54 40.8071 54 27Z" fill="white"/>
      </g>
      <path d="M36.5 27H21V28.5H36.5V27Z" fill="#3E9A36"/>
      <defs>
        <filter id="filter0_d_2047_16651" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.502 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2047_16651"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2047_16651" result="shape"/>
        </filter>
      </defs>
    </svg>

  )
}

export default ZoomButtonMinus;