import React, { FC } from "react";
import "./ZoomNavigation.scss";
import ZoomButtonMinus from "../ZoomButtonMinus/ZoomButtonMinus";
import ZoomButtonPlus from "../ZoomButtonPlus/ZoomButtonPlus";

interface IProps {
  onPlus: () => void;
  onMinus: () => void;
}

const ZoomNavigation: FC<IProps> = ({onPlus, onMinus}) => {
  return (
    <div className="zoom-navigation">
      <div className="zoom-navigation__plus" onClick={onPlus}>
        <ZoomButtonPlus/>
      </div>
      <div className="zoom-navigation__minus" onClick={onMinus}>
        <ZoomButtonMinus/>
      </div>
    </div>
  )
}

export default ZoomNavigation;